
import "./CardFooter.css"
function CardFooter() {

    return (
        <footer>
            <a className="footer nounderline link-secondary" href="https://www.nectarina.pl">&copy;2022 | copyright nectarina.pl</a>
        </footer>
    )
}

export default CardFooter;